<template>
  <!-- 我的项目----草稿箱 -->
  <div id="MyDrafts">
    <div
      class="Content"
      v-if="continues == 0"
      :class="$store.state.lang == 'en' ? 'english' : ''"
    >
      <!-- 头部说明按钮 -->
      <div class="title-bar">
        <span class="havepj">{{ $t(`message.Project.draftTitle`) }}</span>
        <!-- 项目编号 -->
        <div class="pjID">
          <span>{{ $t(`message.Project.Item`) }}</span>
          <input
            type="text"
            v-model="product_name"
            class="pjid"
          />
        </div>
        <!-- 创建时间 -->
        <div class="createTime">
          <span>{{ $t(`message.Project.CreationTimes`) }}</span>
          <div class="block">
            <el-date-picker
              v-model="value1"
              type="daterange"
              :range-separator="$t(`message.Project.To`)"
              :start-placeholder="$t(`message.Project.start`)"
              :end-placeholder="$t(`message.Project.end`)"
              value-format="yyyy/MM/dd"
              @change="pickerChangeFn"
            >
            </el-date-picker>
          </div>
        </div>
        <!-- 产品信息 -->
        <div class="message">
          <span>{{ $t(`message.Project.information`) }}</span>
          <input
            type="text"
            v-model="product_message"
            class="value"
          />
        </div>
        <!--项目按钮 button -->
        <div
          class="button"
          v-show="$store.state.lang == 'cn'"
        >
          <img
            src="https://newstore.vynior.com/hthj5.png"
            @click="Search"
          />
          <img
            src="https://newstore.vynior.com/hthj6.png"
            @click="Reset"
            class="rest"
          />
          <img
            @click="notarize_delete"
            src="https://newstore.vynior.com/plsc.png"
            alt=""
          />
        </div>
        <!-- english -->
        <div
          class="button"
          v-show="$store.state.lang == 'en'"
        >
          <img
            src="https://newstore.vynior.com/ENsearch.png"
            @click="Search"
          />
          <img
            src="https://newstore.vynior.com/ENreset.png"
            @click="Reset"
            class="rest"
          />
          <img
            @click="notarize_delete"
            src="https://newstore.vynior.com/deletIn.png"
            alt=""
            class="deleteAll"
          />
        </div>
      </div>
      <div class="content_box">
        <!-- 内容标题 -->
        <div class="content-Title">
          <div class="check">
            <input
              type="checkbox"
              @click="checkAll"
              :checked="allin"
            />
          </div>
          <span class="item"> {{ $t(`message.Project.item`) }}</span>
          <div class="checkbox-img">
            <span>{{ $t(`message.Project.creationTimes`) }}</span>
            <img
              src="https://newstore.vynior.com/hthj8.png"
              class="by_order"
              @click="sort_list"
            />
          </div>
          <span class="category">{{ $t(`message.Project.category`) }}</span>
          <span class="number">{{ $t(`message.Project.quantity`) }}</span>
          <span class="name">{{ $t(`message.Project.productName`) }}</span>
          <span class="operation">{{ $t(`message.Project.operation`) }}</span>
        </div>

        <!-- 内容 -->
        <div class="data_maxbox">
          <div
            class="content-message"
            :class="[index === Res_Start_data.result.length - 1 ? 'displayLine' : '']"
            v-for="(item, index) in Res_Start_data.result"
            :key="index"
          >
            <div class="data_box">
              <div class="checked">
                <input
                  type="checkbox"
                  :checked="allOne[index]"
                  @click="checkOne(index)"
                />
              </div>
              <div class="message-id">
                <div style="width: auto; max-width: 159px">
                  <el-tooltip
                    :open-delay="500"
                    class="item"
                    effect="dark"
                    :content="item.program_name"
                    placement="top"
                    popper-class="back_ground"
                  >
                    <span>{{ item.program_name }}</span>
                  </el-tooltip>
                </div>
              </div>

              <div class="message-time">
                <span>{{ item.create_time }}</span>
              </div>

              <div class="message-classify">
                <span>{{ item.category }}</span>
              </div>

              <div class="message-num">
                <span>{{ item.productsnumber }}</span>
              </div>

              <div class="message-name">
                <div style="width: auto; max-width: 540px">
                  <el-tooltip
                    :open-delay="500"
                    class="item"
                    effect="dark"
                    :content="item.productsnamelist.toString()"
                    placement="top"
                    popper-class="back_ground_max"
                  >
                    <span style="max-width: 541px; display: inline-block; overflow: hidden; text-overflow: ellipsis; white-space: nowrap">{{
                      item.productsnamelist.toString()
                    }}</span>
                  </el-tooltip>
                </div>
              </div>

              <span
                class="message-cz1"
                @click="Continue_rite(index)"
              >{{ (item.cz1 = $t(`message.Project.editing`)) }}</span>
              <span
                class="message-cz2"
                @click="check_delte(index)"
              >{{ (item.cz2 = $t(`message.Project.delete`)) }}</span>
            </div>
          </div>
        </div>
        <!-- 搜索为空 -->
        <div
          class="empty_data"
          v-show="empty_ds"
        >
          <div class="img">
            <img
              src="https://newstore.vynior.com/kong.png"
              alt=""
            />
          </div>
          <div class="message">{{ $t(`message.Project.NoResults`) }}</div>
          <div
            class="message2"
            v-show="$store.state.lang == 'cn'"
          >没有搜索到相关内容</div>
        </div>
        <!-- 账号下面空项目 -->
        <div
          class="empty_datas"
          v-show="draftEmpty"
        >
          <div class="img">
            <img
              src="https://newstore.vynior.com/3333.png"
              alt=""
            />
          </div>
          <div class="message">{{ $t(`message.Project.emptyDraft`) }}</div>
        </div>
      </div>
      <!-- 分页 -->
      <div class="page_footer">
        <!-- 项目总和 -->
        <div
          class="projectNum"
          v-show="$store.state.lang == 'cn'"
        >
          <span v-show="searchStatus == true">已选择 <span class="choiceNum">{{ remember_choose.length }}</span>&nbsp;个项目&nbsp;|&nbsp;共&nbsp;&nbsp;{{ choiceNum }} &nbsp;个项目</span>
          <span v-show="searchStatus == false">已选择 <span class="choiceNum">{{ remember_choose.length }}</span>&nbsp;个项目&nbsp;|&nbsp;搜索到&nbsp;&nbsp;{{ choiceNum }} &nbsp;个项目</span>
        </div>
        <!-- english -->
        <!-- 项目总和 -->
        <div
          class="projectNum"
          v-show="$store.state.lang == 'en'"
        >
          <span v-show="searchStatus == true">
            <span class="choiceNum">{{ remember_choose.length }} </span>&nbsp;projects selected&nbsp;|&nbsp;&nbsp;&nbsp;{{ choiceNum }} &nbsp;in
            total</span>
          <span v-show="searchStatus == false">
            <span class="choiceNum">{{ remember_choose.length }}</span>&nbsp;projects selected &nbsp;|&nbsp;&nbsp;{{ choiceNum }}&nbsp;projects found &nbsp;</span>
        </div>
        <!-- 页码展示 -->
        <template>
          <div
            class="blocks"
            v-show="Res_Start_data.totalpage != 0"
          >
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage3"
              :page-size="10"
              layout="prev, pager, next, jumper"
              :total="sum"
              background
            >
            </el-pagination>
          </div>
        </template>
      </div>

      <CreateProject v-if="continues == 1" />
      <router-view />
      <!-- 确认批量删除操作 -->
      <div
        class="notarize_delete"
        v-show="Once_sure"
      >
        <!-- 遮罩层 -->
        <!-- 提示框 -->
        <div class="notarize_message">
          <!-- 内容信息 -->
          <div class="content_text">
            <div
              class="line_1"
              v-show="$store.state.lang == 'cn'"
            >
              确定批量删除&nbsp;<span class="all_num">{{ remember_choose.length }}</span> 条产品信息吗?
              <img
                src="https://newstore.vynior.com/xxx.png"
                alt=""
                @click="Once_sure = 0"
              />
            </div>
            <div
              class="line_1"
              v-show="$store.state.lang == 'en'"
            >
              Are you sure you want to delete&nbsp;<span class="all_num">{{ remember_choose.length }}</span> product?
              <img
                src="https://newstore.vynior.com/xxx.png"
                alt=""
                @click="Once_sure = 0"
              />
            </div>
            <div class="line_2">
              <img
                src="https://newstore.vynior.com/gth.png"
                alt=""
              />
              {{ $t(`message.Project.ActionUndone`) }}
            </div>
            <div class="line_3">
              <span
                class="cancle"
                @click="Once_sure = 0"
              > {{ $t(`message.Project.Cancel`) }}</span>
              <span
                class="sure"
                @click="deletes_end"
              > {{ $t(`message.Project.OK`) }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 删除具体项目操作 -->
      <div
        class="notarize_deletes"
        v-show="Once_sureProject"
      >
        <!-- 遮罩层 -->
        <!-- 提示框 -->
        <div class="notarize_message">
          <!-- 内容信息 -->
          <div class="content_text">
            <div
              class="line_1"
              :class="Once_sureProject == 1 ? 'changeColor' : ''"
              v-show="$store.state.lang == 'cn'"
            >
              确定删除&nbsp;<span>{{ delete_name }}</span> 项目吗?
              <img
                src="https://newstore.vynior.com/xxx.png"
                alt=""
                @click="Once_sureProject = 0"
              />
            </div>

            <div
              class="line_1"
              :class="Once_sureProject == 1 ? 'changeColor' : ''"
              v-show="$store.state.lang == 'en'"
            >
              Are you sure you want to delete &nbsp;<span>{{ delete_name }}</span>?
              <img
                src="https://newstore.vynior.com/xxx.png"
                alt=""
                @click="Once_sureProject = 0"
              />
            </div>
            <div class="line_2">
              <img
                src="https://newstore.vynior.com/gth.png"
                alt=""
              />
              {{ $t(`message.Project.ActionUndone`) }}
            </div>
            <div class="line_3">
              <span
                class="cancle"
                @click="Once_sureProject = 0"
              >{{ $t(`message.Project.Cancel`) }}</span>
              <span
                class="sure"
                @click="delete_end"
              >{{ $t(`message.Project.OK`) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HttpClient } from '@/utils/http';
import CreateProject from './CreateProject.vue';
export default {
  name: 'MyDrafts',
  data() {
    return {
      draftEmpty: false, //账号下为空
      searchStatus: true, //控制搜索的状态显示
      page_current: -1,
      remember_choose: [], //记住勾选的项目id
      sort_value: false, //排序展示"
      empty_ds: false,
      change_page: 0,
      sum: 0,
      pages: 0, //页码数量
      Res_program: [], //存储请求数据
      // 控制继续编辑
      continues: 0,
      //删除具体项目
      Once_sureProject: 0,
      // 项目名称
      project_name: '',
      // 控制再次确认的值。
      Once_sure: 0,
      value1: [],
      value2: '  ',

      choiceNum: 0,
      projectNum: 0,
      // 页面当前页
      currentPage3: 1,
      //   多选
      allin: false,
      //  单选
      allOne: [], //选择的个数
      allCount: 0,
      // 处理数据
      Res_Start_data: [],

      cz_arr: { cz1: '继续编辑', cz2: '删除' },

      // 搜索数据:
      product_name: '',
      product_message: '',
      // 删除数据名字
      delete_name: '',
      delete_sure: 0,
      delete_index: -1,
      continue_message: [], //存储继续编辑的项目的具体信息
    };
  },
  components: {
    CreateProject,
  },
  watch: {
    page_current: {
      handler(newv, oldv) {
        console.log('当前页面', newv);
        console.log('旧的页面', oldv);
        if (newv != oldv) {
          this.allin = false;

          for (let index = 0; index < this.allOne.length; index++) {
            this.allOne[index] = false;
          }
        }
      },
    },
    //NOTE 多选记住操作,后续可能要修改
    Res_Start_data: {
      handler(newval, oldval) {
        if (newval != oldval) {
          console.log('监听当前页的数据', newval.result);
          console.log('当前勾选的id是:', this.remember_choose);
          console.log(this.allOne);
          //逻辑问题 强制归零
          for (let index = 0; index < 10; index++) {
            this.allOne[index] = false;
          }
          //判断勾选的id 在当前页面是否存在，存在就勾选（true）
          for (let y = 0; y < this.remember_choose.length; y++) {
            for (let z = 0; z < newval.result.length; z++) {
              if (this.remember_choose[y] == newval.result[z].programid) {
                console.log('勾选下标为', z);
                this.allOne[z] = true;
              }
            }
          }

          // 同步全选按钮
          var num = 0;
          //判断当前页面勾选的数量
          for (let index = 0; index < this.allOne.length; index++) {
            if (this.allOne[index] == true) {
              num++;
            }
          }
          console.log('全选匹配前', this.allOne);
          console.log('当前页面的勾选数量是', num);
          // 用勾选的数量和当前页的数量作对比，=则是全选。
          if (newval.result.length == num && newval.result.length != 0) {
            this.allin = true;
          }
          console.log('全选匹配前', this.allOne);
        }
      },
    },
    "$store.state.lang":{
       handler(){
        console.log("监听语言切换");
        if(this.$store.state.lang=="cn"&&this.Res_Start_data){
          this.Res_Start_data.result.forEach((item,index)=>{
              if(item.category == "Sun care"){
                  this.Res_Start_data.result[index].category = "防晒霜"
              }
          })
        }else if(this.$store.state.lang=="en"&&this.Res_Start_data){
          console.log("en");
          this.Res_Start_data.result.forEach((item,index)=>{
              if(item.category == "防晒霜"){
                  this.Res_Start_data.result[index].category = "Sun care"
              }
          })
        }
       },
      immediate:true,
    }
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page_current = val;
      this.pages = val - 1;
      this.fist_req();
      // if (this.change_page) {
      //   this.fist_req();
      // } else {
      // this.Search();
      // }
    },

    // 全选
    checkAll() {
      console.log('我执行了全选');
      if (this.allin == false) {
        console.log(this.Res_Start_data.result);
        console.log('全选,初始记住id数组', this.remember_choose);

        console.log(this.allin);
        //所有复选框的数组
        console.log(this.allOne);

        //如果记住id数组为空 就直存
        if (this.remember_choose.length == 0) {
          for (let index = 0; index < this.Res_Start_data.result.length; index++) {
            this.remember_choose.push(this.Res_Start_data.result[index].programid);
          }
        } else {
          //不为空就判断之后再存
          //点击之后 把所有的当前页的项目 id 存入到id_list
          for (let y = 0; y < this.Res_Start_data.result.length; y++) {
            if (this.remember_choose.indexOf(this.Res_Start_data.result[y].programid) == -1) {
              this.remember_choose.push(this.Res_Start_data.result[y].programid);
            }
          }

          console.log('全选后的的记住id是', this.remember_choose);
        }

        this.allCount = this.Res_Start_data.result.length;
        this.allin = true;
        for (let index = 0; index < this.Res_Start_data.result.length; index++) {
          this.allOne[index] = true;
        }
      } else {
        this.allin = false;
        //allCount 选中的数量
        this.allCount = 0;

        //删除 全选的 remeber_id
        for (let x = 0; x < this.remember_choose.length; x++) {
          for (let index = 0; index < this.Res_Start_data.result.length; index++) {
            if (this.remember_choose[x] == this.Res_Start_data.result[index].programid) {
              this.remember_choose.splice(x, 1);
            }
          }
        }
        console.log('全选后的的删除的id是', this.remember_choose);
        for (let index = 0; index < this.Res_Start_data.result.length; index++) {
          this.allOne[index] = false;
        }
        console.log(this.allOne);
      }
    },
    // 单选
    checkOne(val) {
      console.log(val);
      //勾选
      if (!this.allOne[val]) {
        this.allOne[val] = true;
        this.add_remember(val);
        console.log(this.allOne);
      } else {
        //取消勾选
        this.allOne[val] = false;
        this.delete_remember(val);
      }
      console.log(this.allOne);
      this.allCount = 0;
      for (let index = 0; index < this.Res_Start_data.result.length; index++) {
        if (this.allOne[index] == true) {
          this.allCount++;
        }
      }
      // console.log(this.allCount);
      // console.log(this.allOne);
      // console.log(this.Res_Start_data.result.length);
      if (this.allCount == this.Res_Start_data.result.length) {
        this.checkAll();
      } else {
        this.allin = 0;
      }
    },

    //增加记住的id
    add_remember(val_) {
      this.remember_choose.push(this.Res_Start_data.result[val_].programid);
      console.log('增加过后的记住数组', this.remember_choose);
    },
    //删除记住的id
    delete_remember(val_) {
      console.log(this.Res_Start_data.result[val_].programid);
      for (let index = 0; index < this.remember_choose.length; index++) {
        if (this.Res_Start_data.result[val_].programid == this.remember_choose[index]) {
          this.remember_choose.splice(index, 1);
        }
      }
      console.log('删除之后的记住数组', this.remember_choose);
    },

    // 确认多选删除操作
    notarize_delete() {
      if (this.allCount != 0) {
        this.Once_sure = 1;
      } else {
        this.$message({
          showClose: true,
          message: this.$t(`message.Message.Batchoperation`),
          type: 'warning',
        });
      }
    },
    // 单选删除操作
    check_delte(index) {
      // if (this.allCount < 2) {
      // 给删除操作赋值名称
      this.delete_name = this.Res_Start_data.result[index].program_name;
      this.Once_sureProject = 1;
      // 存入下标
      this.delete_index = index;
      // }
    },
    // 初次加载页面的数据
    async fist_req() {
      this.remember_choose = [];
      this.change_page = 1;
      // 页面加载完成 发送第一次请求初始数据展示,
      await HttpClient.post('program/searchlist', {
        user_id: this.$store.state.userid,
        project_name: '',
        startdate: '',
        enddate: '',
        productinfo: '',
        upSort: this.sort_value,
        status: '草稿箱',
        page: this.pages,
        size: 10,
      }).then((res) => {
        // that.Res_Start_data = [];
        this.searchStatus = true;
        this.sum = res.totalpage;
        this.Res_Start_data = res;
        if(this.$store.state.lang== "cn"){
           if(this.Res_Start_data){
            this.Res_Start_data.result.forEach((item,index)=>{
                if(item.category=="Suncreen"){
                    this.Res_Start_data.result[index].category = "防晒霜"
                }
            })
        }
        }else{
          if(this.Res_Start_data){
            this.Res_Start_data.result.forEach((item,index)=>{
                if(item.category=="Suncreen"||item.category=="防晒霜"){
                    this.Res_Start_data.result[index].category = "Sun care"
                }
            })
        }
        }
        console.log(this.Res_Start_data);
        // 重置 传递过来的数据 增加三个操作.
        for (let index = 0; index < this.Res_Start_data.result.length; index++) {
          this.Res_Start_data.result[index] = Object.assign(this.Res_Start_data.result[index], this.cz_arr);
        }

        if (this.Res_Start_data.result.length == 0) {
          this.draftEmpty = true;
          // this.empty_ds = true;
        } else {
          this.empty_ds = false;
          this.draftEmpty = false;
        }
      });

      //用来请求 总数量
      await HttpClient.post('program/searchlist', {
        user_id: this.$store.state.userid,
        project_name: '',
        startdate: '',
        enddate: '',
        productinfo: '',
        status: '草稿箱',
        page: 0,
        size: 999,
      }).then((res) => {
        this.choiceNum = res.result.length;

        this.$EventBus.$emit('sendHavaDrafts_Num', this.choiceNum);
      });
    },
    //排序展示
    async sort_list() {
      this.sort_value = !this.sort_value;
      this.change_page = 1;
      // 页面加载完成 发送第一次请求初始数据展示,
      await HttpClient.post('program/searchlist', {
        user_id: this.$store.state.userid,
        project_name: this.product_name,
        startdate:this.value1[0],
        enddate: this.value1[1],
        productinfo:this.product_message,
        upSort: this.sort_value,
        status: '草稿箱',
        page: this.pages,
        size: 10,
      }).then((res) => {
        // that.Res_Start_data = [];
        this.sum = res.totalpage;
        this.Res_Start_data = res;
        console.log(this.Res_Start_data);
        // 重置 传递过来的数据 增加三个操作.
        for (let index = 0; index < this.Res_Start_data.result.length; index++) {
          this.Res_Start_data.result[index] = Object.assign(this.Res_Start_data.result[index], this.cz_arr);
        }

        if (this.Res_Start_data.result.length == 0) {
          this.empty_ds = true;
        } else {
          this.empty_ds = false;
        }
      });

      //用来请求 总数量
      await HttpClient.post('program/searchlist', {
        user_id: this.$store.state.userid,
        project_name: '',
        startdate: '',
        enddate: '',
        productinfo: '',
        status: '草稿箱',
        page: 0,
        size: 999,
      }).then((res) => {
        this.choiceNum = res.result.length;

        // 同步下方的 选择个数和 总体项目数量
        // this.choiceNum = this.Res_Start_data.result.length;
        console.log(this.Res_Start_data.result);

        for (let index = 0; index < this.Res_Start_data.result.length; index++) {
          this.allOne[index] = false;
        }
        this.allCount = 0;
        this.$EventBus.$emit('sendHavaDrafts_Num', this.choiceNum);
      });
    },

    // 搜索按钮
    Search() {
      this.change_page = 0;
      const that = this;
      that.draftEmpty = false;

      if (that.value1[0] === undefined) {
        that.value1.splice(0, 1, '');
      }
      if (that.product_name === undefined) {
        that.product_name = '';
      }
      if (that.value1[1] === undefined) {
        that.value1.splice(1, 1, '');
      }
      if (that.product_message === undefined) {
        that.product_message = '';
      }
      // console.log(that.product_name);
      // console.log(that.value1);
      // console.log(that.value1[0]);
      // console.log(that.value1[1]);
      // console.log(that.product_message);
      this.searchStatus = false;
      // 点击搜索发送请求,
      HttpClient.post('program/searchlist', {
        user_id: that.$store.state.userid,
        project_name: that.product_name,
        startdate: that.value1[0],
        enddate: that.value1[1],
        productinfo: that.product_message,
        status: '草稿箱',
        page: 0,
        size: 10,
      }).then((res) => {
        that.Res_Start_data = [];
        that.Res_Start_data = res;
        that.sum = that.Res_Start_data.totalpage;
        // 重置 传递过来的数据 增加三个操作.
        for (let index = 0; index < that.Res_Start_data.result.length; index++) {
          that.Res_Start_data.result[index] = Object.assign(that.Res_Start_data.result[index], that.cz_arr);

          that.allOne[index] = false;
        }
        if (this.Res_Start_data.result.length == 0) {
          this.empty_ds = true;
        } else {
          this.empty_ds = false;
        }
        // 同步下方的 选择个数和 总体项目数量
        this.choiceNum = this.Res_Start_data.result.length;
        console.log(that.Res_Start_data.result);
        // this.$EventBus.$emit('sendHavaDrafts_Num', this.choiceNum);
      });
    },

    // 重置按钮
    Reset() {
      const that = this;
      that.product_message = '';
      that.value1 = [];
      that.product_name = '';
      this.searchStatus = true;
      that.fist_req();
      that.empty_ds = false;
    },
    //确认删除按钮
    async delete_end() {
      this.Once_sureProject = 0;

      // 请求删除数据
      await HttpClient.post('program/delete', {
        id: this.Res_Start_data.result[this.delete_index].programid,
      }).then((res) => {
        // 删除返回信息
        console.log(res);
        // this.allin = false;
      });

      // 重新加载
      this.fist_req();
      this.allOne = false;
      console.log(this.allOne);
    },
    // 确认多选删除按钮
    async deletes_end() {
      this.Once_sure = 0;
      // const delete_i = [];
      for (let index = 0; index < this.remember_choose.length; index++) {
        await HttpClient.post('program/delete', {
          id: this.remember_choose[index],
        }).then((res) => {
          // 删除返回信息
          console.log(res);
          this.allin = false;
        });
      }

      // 重新加载
      this.fist_req();

      // console.log(this.allOne);
    },
    // 查看继续编辑的项目信息请求
    async continue_req(i) {
      this.$store.commit('$_change_upload', false);
      console.log('收到并发送请求');
      await HttpClient.post('program/open1', {
        id: this.Res_Start_data.result[i].programid,
      }).then((res) => {
        this.continue_message = res;
      });
      console.log(this.continue_message);
      this.$EventBus.$emit('sendDraft', this.continue_message);
    },

    // 继续编辑->前往新建页面
    Continue_rite(i) {
      // this.continues = 1;
      this.$EventBus.$emit('pageChange', 'CreateProject');
      console.log(this.Res_Start_data.result[i]);

      this.continue_req(i);
    },
    //日期选择器清空之后的操作。
    pickerChangeFn(value) {
      // 日期选择器改变事件 ~ 解决日期选择器清空 值不清空的问题
      if (value === null) {
        // this.parms.beginTime = value
        this.fist_req();
        // this.parms.endTime = value
      }
    },
  },
  mounted() {
  
  },
  created(){
  this.fist_req();
  },
  beforeDestroy() {
    // console.log('DrafteforeDestroy :>> ');
  },
  destroyed() {
    // console.log('Checkbdestroyed :>> ');
  },
};
</script>

<style lang="less" scoped>
@import url("../../style/Project/MyDrafts.less");
</style>
